@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}
.button-style {
  @apply px-4 py-3 rounded-sm font-bold text-white text-sm sm:text-base transition-colors ;
}
